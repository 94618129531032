import React from 'react';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';

const AdminToolbar = () => (
    <Toolbar>
        <SaveButton label="保存" />
        <DeleteButton label='删除' />
    </Toolbar>
);

export default AdminToolbar;