import React from 'react';
//import ExtendedDataProvider from './ExtendedDataProvider';
import { Admin, Resource } from 'react-admin';
import CustomerList from './CustomerList';
//import CustomerEdit from './CustomerEdit';
import OrderList from './OrderList';
import OrderEdit from './OrderEdit';
//import OrderCreate from './OrderCreate';
import CompletedOrderList from './CompletedOrderList';
import CompletedOrderEdit from './CompletedOrderEdit';
import ReviewList from './ReviewList';
import VerificationTokenList from './VerificationTokenList';
import { AdminLayout } from './AdminLayout';
import SimpleRestProvider from "ra-data-simple-rest";
import { AuthProvider } from './AuthProvider';
//import WatchlistList from './WatchlistList';
//import { fetchUtils } from 'react-admin';


function App() {
  const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
  const dataProvider = SimpleRestProvider(API_URL);
  //console.log('baseurl: '+BASE_URL);
  //const httpClient = 'http://localhost:3000';
  /*
  const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    options.headers.set('X-Custom-Header', 'foobar');
    options.headers.set('withCredentials', true);
    return fetchUtils.fetchJson(url, options);
  }
  */
 // authProvider={AuthProvider}
  return (
    <div className="App">
      <Admin layout={AdminLayout} dataProvider={dataProvider} authProvider={AuthProvider}>
      <Resource name='customers' options={{label: 'customers'}} list={CustomerList}/>
      <Resource name='orders' options={{label: 'pending orders'}} list={OrderList}
                                                                  edit={OrderEdit}/>
      <Resource name='completedOrders' options={{label: 'completed orders'}} list={CompletedOrderList}
                                                                             edit={CompletedOrderEdit}/>
      <Resource name='reviews' options={{label: 'reviews'}} list={ReviewList}/>
      <Resource name='tokens' options={{label: 'tokens'}} list={VerificationTokenList}/>
    </Admin>
    </div>
  );
}

export default App;
