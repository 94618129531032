const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;

export const AuthProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
      const request = new Request(API_URL+'/adminlogin', {
        method: 'POST',
        body: JSON.stringify({username, password}),
        headers: new Headers({'Content-Type': 'application/json'}),
      });

      return fetch(request)
                  .then((response) => {
                    if (response.status===200) {
                      localStorage.setItem('accessToken', JSON.stringify(response.accessToken));
                    }
                  }).catch((error) => {
                    console.log(error);
                });
    },
    // called when the user clicks on the logout button
    logout: () => {
      localStorage.removeItem("accessToken");
      return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
      if (status === 401 || status === 403) {
        localStorage.removeItem("accessToken");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
      return localStorage.getItem("accessToken")
        ? Promise.resolve()
        : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
  };
  