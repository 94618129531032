import React from "react";
import { Menu } from "react-admin";

export const AdminMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.Item to='/customers' primaryText='用户' />
        <Menu.Item to='/orders' primaryText='订单' />
        <Menu.Item to='/completedOrders' primaryText='已完成订单' />
        <Menu.Item to='/reviews' primaryText='评价' />
        <Menu.Item to='/tokens' primaryText='验证令牌' />
    </Menu>
);