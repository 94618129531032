import React from 'react';
import AdminToolbar from './AdminToolbar';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput} from 'react-admin';


const CompletedOrderEdit = (props) => (
  <Edit {...props} title='编辑订单'>
    <SimpleForm toolbar={<AdminToolbar />}>
    <TextInput disabled source='id' label='订单号'/>
      <TextInput disabled source='customerID' label='用户'/>
      <TextInput disabled source='paymentIntentID' label='付款号'/>
      <TextInput source='paymentStatus' label='付款状态'/>
      <SelectInput source="status_code" label='订单状态码' choices={[
                                               { id: 0, name: 'Order Paid' },
                                               { id: 1, name: 'Order Packed' },
                                               { id: 2, name: 'Order Shipped' },
                                               { id: 3, name: 'Order Delivered' },
                                               { id: 4, name: 'Order Completed' },
                                               { id: 5, name: 'Order Canceled' }
                                              ]} />
      <SelectInput source="status" label='订单状态' choices={[
                                               { id: 'Awaiting Order Returned', name: 'Awaiting Order Returned' },
                                               { id: 'Order returned while refund being processed', name: 'Order returned while refund being processed' },
                                               { id: 'Refund succeeded', name: 'Refund succeeded' },
                                              ]} />
      <TextInput disabled source='currency' label='货币'/>
      <TextInput disabled source='carrier' label='快递公司'/>
      <TextInput disabled source='date' label='日期'/>
      <NumberInput disabled source='itemsTtotal' label='产品金额'/>
      <NumberInput disabled source='freight' label='运费'/>
      <NumberInput disabled source='tax' label='税'/>
      <NumberInput disabled source='total' label='订单金额'/>
    </SimpleForm>
  </Edit>
);

export default CompletedOrderEdit;